import React from 'react';
import config from '../../config';
export default function SocialLinks() {
  return (
    <footer id="TestMe">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;          
          return (
           
            <li key={name} className="list-inline-item">
                <a
                  href={url}
                  className="social-link rounded-circle text-white mr-3"
                >
                  <i className={`icon ${style} ${icon}`}></i>
                </a>
            </li>
           
          );
        })}
      </ul>
    </footer>
  );
}
