import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
import SocialLinks from '../components/SocialLinks';


import pic1 from '../assets/images/Anadarko.png';
import pic2 from '../assets/images/adnoc.jpg';
import pic3 from '../assets/images/well.jpg';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            ENGINEER AND CONSULTANT WITH EXTENSIVE
            <br /> EXPERTISE IN DIGITAL OILFIELD PROJECTS
          </h2>
          <p>
            As a freelancer with a proven record of more than 6 years in the Oil and Gas industry, I provide solutions for Integrated Asset Modelling, Production Engineering, Data Analytics, and Project Management applied to digital development. 
            <br />
            I’m currently working in the Data Science Team in one of the biggest oilfields in the world:   <br /> Rumaila field, Republic of Iraq.
          </p>
        </header>
        <SocialLinks />
      </div>
    </section>
    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>MY EXPERTISE</h2>
          <p>
            Working in a digital oilfield project often requires a complex combination of different skills. I can help Your organization with a complete set of services, combining a strong Oil and Gas domain knowledge with a peculiar experience with new digital tools.
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-bezier-curve">
            <h3>INTEGRATED ASSET MODELLING</h3>
            <p>
              Building of integrated reservoir, wells and facility models, using the Petex IPM suite: MBAL, PROSPER and GAP. 
              Integration of models with field data to create the “digital twin”.     
            </p>
          </li>
          <li className="icon solid fa-network-wired">
            <h3>DIGITAL OILFIELDS</h3>
            <p>
             Integrator of different Petrotechnical applications into unified solutions. Full proficiency in complex workflows development in Petex Integrated Field Management (IFM) and Integrated Visualization Management (IVM).
            </p>
          </li>
          <li className="icon solid fa-chart-bar">
            <h3>DATA ANALYTICS</h3>
            <br />
            <p>
              Experience in analysing and interpreting data from the field using innovative statistical techniques in tools such as Python, TIBCO Spotfire, and Petex IVM.
            </p>
          </li>
          <li className="icon solid fa-tint">
            <h3>PETROLEUM ENGINEERING</h3>
            <p>
              Expertise in different production systems: gas lifted, ESP, and naturally flowing wells; onshore and offshore installations; conventional and unconventional reservoirs.
            </p>
          </li>
          <li className="icon solid fa-database">
            <h3>DATA MANAGEMENT</h3>
            <br />
            <p>
              Creation of the right data ecosystem to enable and sustain an effective digital oilfield initiative. Expertise in OSIsoft PI Asset Framework and SQL relational databases.
            </p>
          </li>
          <li className="icon solid fa-chalkboard-teacher">
            <h3>PROJECT MANAGEMENT and TRAINING</h3>
            <p>
              Soft skills matter too. I can guide and train the project team in the digital transformation journey. Agile and Scrum are not just buzzwords, but assets to enhance productivity and empower teams.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section id="two" className="wrapper alt style2">
      <header className="major" style={{textAlign: 'center', paddingTop: '40px'}}>
        <h2>CASE STUDIES</h2>
        <p>Some examples of my work. Follow the links in the descriptions for more details.</p>
      </header>
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" /> 
        </div>
        <div className="content">
          <h3>
            AUTOMATIC WELL INTERFERENCE IDENTIFICATION AND CHARACTERIZATION: A DATA-DRIVEN APPROACH TO IMPROVE FIELD OPERATION
          </h3>
          <p>
            An automatic data-driven workflow developed to identify inter-well interference events and their impact on estimated ultimate recovery (EUR) based on changes in the well productivity trend, using routinely collected data.
            <br/>
            <a href="https://www.onepetro.org/conference-paper/SPE-195813-MS">More...</a>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h3>
            INTEGRATED DATA ANALYTICS AND VISUALIZATION FOR RESERVOIR AND PRODUCTION PERFORMANCE MANAGEMENT
          </h3>
          <p>
            Development of integrated reservoir and production performance visualization dashboards configured to monitor key reservoir and production performance information on a daily and periodic basis. The solution facilitates collaborative review sessions with OPCO teams to initiate and track actions.
            <br />
            <a href="https://www.onepetro.org/conference-paper/SPE-192987-MS">More...</a>
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h3>
            PRODUCTION SURVEILLANCE VIA REAL-TIME WELL MODELS
          </h3>
          <p>
            An innovative upstream petroleum digital solution, to integrate data and models in a collaborative web portal. The new “Smart Portal” is set to provide the required intelligence to asset team so they take the right decisions.

            <br />
            <a href="https://www.osisoft.com/presentations/daleel-petroleum---production-surveillance-via-real-time-well-models/">More...</a>
          </p>
        </div>
      </section>
    </section>

    
    <section id="cta" className="wrapper style4">
      <div className="inner">
      
        <br /><br /><br /><br />
      </div>
    </section>
  </Layout>

);


export default IndexPage;

